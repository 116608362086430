<template>
  <div>
    <a-breadcrumb>
      <a-breadcrumb-item
        ><a href="/orders2/Orders">Orders</a></a-breadcrumb-item
      >
      <a-breadcrumb-item>View past bills</a-breadcrumb-item>
    </a-breadcrumb>
    <a-page-header
      class="header_title"
      title="View past bills"
      @back="() => $router.go(-1)"
    />

    <a-form layout="inline" :form="form" @submit="handleSubmit">
      <a-form-item
        :style="{ display: 'inline-block', width: 'calc(200px - 12px)' }"
      >
        <a-month-picker v-decorator="[
          'search_date',
          { rules: [{ required: false, message: 'Please select mediatype!' }] },
        ]"
        format="YYYY-MM"
        style="width: 100%" />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit"> Search </a-button>
      </a-form-item>

      <a-form-item label="Settlement amount"> CNY2000 </a-form-item>
    </a-form>

    <a-table
      :columns="columns"
      :data-source="data"
      :rowKey="(record, index) => index"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
      size="middle"
    >
      <span slot="Products" slot-scope="text, data" class="td_black">
        <img
          v-bind:src="data.img"
          style="width: 50px; float: left; margin-right: 5px"
        />
        <p>{{ data.Products }}</p>
      </span>

      <span slot="Status" slot-scope="text, data">
        {{ data.Status }}
      </span>

      <span slot="action" slot-scope="text, data">
        <button class="btn" @click="showStatus(data.Order, data.Status)">
          View details
        </button>
      </span>
    </a-table>
  </div>
</template>
<script>
import { getOrders, getOrderDetail } from "@/network/order2Reuqest";
const columns = [
  {
    title: "Product(s)",
    key: "Products",
    dataIndex: "Products",
    scopedSlots: { customRender: "Products" },
  },
  {
    title: "Total Price",
    dataIndex: "TotalPrice",
    key: "TotalPrice",
  },
  {
    title: "Status",
    key: "Status",
    scopedSlots: { customRender: "Status" },
  },
  {
    title: "Action",
    dataIndex: "Action",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "NodeprojectMedia",
  components: {},
  directives: {},
  data() {
    return {
      columns,
      loading: false,
      visible: false,
      page: 1,
      data: [],
      total_rows: 0,
      pagination: {},
      form: this.$form.createForm(this, { name: "user_from" }),
    };
  },
  created() {
    this.getList(1);
  },
  mounted() {},
  methods: {
    getList(page) {
      getOrders(page).then((res) => {
        if (res.header.code == 200) {
          this.total_rows = res.body.total_rows;
          this.data = res.body.orders;
        }
      });
    },
    handleSubmit(e) {
        e.preventDefault();
        this.form.validateFields((err, values) => {
          if (!err) {
            if(values['search_date']){
              let rangeTimeValue = values['search_date'].format('YYYY-MM-DD');
              console.log(rangeTimeValue);
            }
          }
      });
    },
    handleTableChange(pagination, filters, sorter) {
      this.page = pagination.current;
      getList(pagination.current);
    },
    showStatus() {
      this.$router.push({ path: `/orders2/historyStatus` });
    },
  },
};
</script>

<style  scoped>
</style>

